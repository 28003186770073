<template>
  <div class="rounded-checkbox">
    <v-btn
      class="checkbox-round-button non-focused"
      fab
      width="30"
      height="30"
      small
      :color="color"
      :outlined="!checked"
      dark
      @click.stop="toggle"
    >
      <!-- <v-slide-y-reverse-transition> -->
        <v-icon small v-if="checked">{{icon}}</v-icon>
      <!-- </v-slide-y-reverse-transition> -->
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "RoundedCheckbox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
        type: String,
        default: "mdi-check"
    },
    color: {
        type: String,
        default: 'primary'
    }
  },
  data() {
    return {
      checked: this.value,
    };
  },
  methods: {
    toggle() {
      this.checked = !this.checked;
      this.$emit("valueChanged", this.checked);
    },
  },
  watch: {
    value: {
      handler() {
        this.checked = this.value;
      }
    }
  }
};
</script>